<template>
  <partner-center-guest v-if="!isLogin || !user.partner_id" />
  <div class="partner-center" :style="bgStyle" v-else>
    <div class="tab-container">
      <div class="tabs" ref="tabs">
        <div
          v-for="(tab, index) in tabs"
          :key="index"
          class="tab unselect"
          :class="{ active: active(tab) }"
          @click="clickTab(tab.path)"
        >
          <icon-partner-center :icon="tab.icon" :color="active(tab) ? '#ff6600' : '#4f4f4f'" />
          <span>{{ tab.name }}</span>
        </div>
      </div>
    </div>
    <div class="page-router">
      <div class="page-router-view">
        <router-view></router-view>
      </div>
    </div>
    <portal-target name="footer"></portal-target>
  </div>
</template>
<script>
import IconPartnerCenter from '@/components/module/IconPartnerCenter.vue';
import PartnerCenterGuest from './PartnerCenterGuest.vue';
export default {
  name: 'PartnerCenter',
  components: {
    IconPartnerCenter,
    PartnerCenterGuest,
  },
  mixins: [],
  props: {},
  created() {
    if(!this.isLogin && this.$route.path !== '/partner_center') {
      this.toast('로그인 후 이용할 수 있습니다.');
      this.$store.commit('setPrevPath', this.$route.fullPath);
      this.$router.replace('/signin');
      return;
    }
    if (this.$route.path === '/partner_center' && this.isLogin && this.user.partner_id) {
      this.$router.replace('/partner_center/my_template');
    }
  },
  mounted() {
    setTimeout(() => {
      this.scrollToActiveTab();
    }, 1000);
  },
  data() {
    return {
      tabs: [
        {
          name: '내 템플릿',
          path: 'my_template',
          icon: 'web-grid',
        },
        {
          name: '고객 문의',
          path: 'customer_inquiry',
          icon: 'comment',
        },
        {
          name: '미팅 관리',
          path: 'meetings',
          icon: 'calendar',
        },
        {
          name: '계약내역',
          path: 'contracts',
          icon: 'file',
        },
        {
          name: '파트너 정보',
          path: 'partner_info',
          icon: 'user',
        },
        {
          name: '파트너 개발자 센터',
          path: 'developer_center',
          icon: 'home',
        },
      ],
    };
  },
  computed: {
    bgStyle() {
      return {
        backgroundColor: this.$route.path.includes('developer_center') ? '#fff' : '#f5f5f5',
        paddingBottom: this.$route.path.includes('partner_info') ? '0' : '64px',
      };
    },
  },
  watch: {
    $route: {
      handler() {
        this.scrollToActiveTab();
      },
      immediate: true,
    },
  },
  methods: {
    equalPath(path) {
      return this.$route.fullPath.replace('/partner_center/', '').split('/')[0] === path;
    },
    active(tab) {
      let active = this.$route.path.includes(tab.path);
      if (!active && tab.path === 'developer_center') {
        active = this.$route.path.includes('developer_request');
      }
      return active;
    },
    scrollToActiveTab() {
      this.$nextTick(() => {
        let activeTab = this.tabs.findIndex((tab) => this.equalPath(tab.path));
        if (this.$route.path.includes('developer_request')) {
          activeTab = this.tabs.length - 1;
        }
        if (activeTab !== -1) {
          const tabElement = this.$refs.tabs.children[activeTab];
          if (tabElement) {
            tabElement.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center',
            });
          }
        }
      });
    },
    clickTab(path) {
      this.$router.push(`/partner_center/${path}`);
      this.$nextTick(() => {
        this.scrollToActiveTab();
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.partner-center
  padding 64px 0
  .tab-container
    display flex
    justify-content center
    align-items center
    background white
    border-bottom 1px solid #e3e3e3
    .tabs
      display flex
      justify-content center
      align-items center
      gap 24px
      .tab
        display flex
        justify-content center
        align-items center
        gap 8px
        padding 20px 0 18px 0
        border-bottom 2px solid transparent
        font-size 15px
        font-weight 500
        color #4f4f4f
      .tab.active
        border-bottom 3px solid #ff6600
        color #ff6600
.page-router
  display flex
  justify-content center
  .page-router-view
    width 1152px
@media (max-width: 600px)
  .partner-center
    padding 60px 0
  .tab-container
    overflow-x auto
    display block
  .page-router
    .page-router-view
      width 100%
      padding 0
  .tab-container
    overflow-x auto
    white-space nowrap
    width 100%
    justify-content flex-start !important
    .tabs
      display inline-flex
      justify-content flex-start
      align-items center
      gap 24px
      .tab
        display inline-flex
        justify-content center
        align-items center
        border-bottom 2px solid transparent
      .tab:first-child
        margin-left 16px
      .tab:last-child
        margin-right 16px
</style>
